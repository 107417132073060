<template>
  <div
    class="
      single-tag-item
      border-radius-6
      padding-20
      border-radius-6
      box-shadow-light-grey
      height-calc-type1
    "
  >
    <c-table
      :dataList="dataList"
      :rowList="rowList"
      :loading="loading"
      :total="total"
      :pageSize="pageSize"
      :isUseCheckbox="true"
      operationWidth="200"
      height="calc(100vh - 320px)"
      @pageChange="pageChange"
      @selectChange="selectChange"
    >
      <template #filter>
        <el-button @click="initData" class="margin-right-ten"
          >刷新数据</el-button
        >
        <el-input
          placeholder="请输入标签信息"
          style="width: 210px"
          class="margin-right-ten"
          v-model="search"
          clearable
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="initData"
          ></el-button>
        </el-input>
        <el-button type="primary" @click="addTag" class="margin-right-ten"
          >新增标签</el-button
        >
        <el-button
          type="danger"
          @click="delGroup"
          :disabled="selection.length === 0"
          >批量删除</el-button
        >
      </template>
      <template #name="{ row }">
        <div v-if="!row.isEditTitle">
          <span>{{ row.name }}</span>
          <i
            @click="editTitle(row)"
            class="el-icon-edit"
            style="
              font-size: 18px;
              cursor: pointer;
              margin-right: 5px;
              color: #00bf8a;
            "
          ></i>
        </div>
        <div v-else>
          <el-input v-model="row.name" placeholder="请输入标题名!">
            <el-button
              slot="append"
              icon="el-icon-circle-check"
              @click="editTitleInfo(row)"
            ></el-button>
          </el-input>
        </div>
      </template>
      <template #tagGroups="{ row }">
        <div class="link-group-list flex-row-start-start-wrap">
          <div class="group-item" v-for="item in row.tagGroups" :key="item.id">
            <span>{{ item.tagGroupName }}</span>
            <i
              class="el-icon-error del-icon"
              @click="unBindItem(item, false, row)"
            ></i>
          </div>
          <el-button
            type="warning"
            plain
            class="button-small"
            size="small"
            icon="el-icon-plus"
            @click="setLinkTag(row)"
            >关联组</el-button
          >
        </div>
      </template>
      <template #buttonOperation="{ row }">
        <el-button
          type="primary"
          size="button-small"
          class="button-small"
          @click="editItem(row)"
          >编辑</el-button
        >
        <el-button
          type="danger"
          size="button-small"
          class="button-small"
          @click="delItem(row)"
          >删除</el-button
        >
      </template>
    </c-table>
    <el-dialog
      title="新增标签"
      width="400px"
      v-dialogDrag
      append-to-body
      :visible.sync="showDialog"
      :before-close="handleClose"
    >
      <div class="add-tag flex-column-start-start">
        <div class="flex-row-start-center" style="margin-bottom: 20px">
          <span
            style="padding-right: 20px; width: 70px; text-align: right"
            class="required"
            >名称:</span
          >
          <el-input
            placeholder="标签名"
            style="width: 200px"
            v-model="tagItemNames"
          ></el-input>
        </div>
        <div class="flex-row-start-center">
          <span style="padding-right: 20px; width: 70px; text-align: right"
            >组名:</span
          >
          <el-select
            placeholder="请关联组名"
            style="width: 200px"
            v-model="tagGroupIds"
            multiple
            clearable
            filterable
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.tagGroupName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="button-list">
        <el-button type="primary" @click="handleComfirm">确定</el-button>
        <el-button @click="Cancel">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑标签"
      width="500px"
      v-dialogDrag
      append-to-body
      :visible.sync="showEditDialog"
      :before-close="handleEditClose"
    >
      <div class="container" v-if="curInfo">
        <div class="fixed-part">
          <div class="part part-one">
            <span>标签项名：</span>
            <el-input
              placeholder="请输入标签名"
              style="width: 200px"
              v-model="copyCurInfoName"
              clearable
            ></el-input>
            <span style="font-weight: bold"
              >已绑定{{
                Array.isArray(curInfo.tagGroups) ? curInfo.tagGroups.length : 0
              }}个标签组</span
            >
          </div>
        </div>
        <div
          class="group-list"
          v-if="
            Array.isArray(curInfo.tagGroups) && curInfo.tagGroups.length > 0
          "
        >
          <h3 style="margin-bottom: 10px">绑定的标签组列表</h3>
          <p v-for="item in curInfo.tagGroups" :key="item.id">
            <span>{{ item.tagGroupName }}</span>
            <el-button
              class="del-button button-small"
              plain
              type="warning"
              size="small"
              @click="unBindItem(item)"
              >解绑</el-button
            >
          </p>
        </div>
        <div class="button-list">
          <el-button type="primary" @click="handleOk">确定</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="编辑"
      width="400px"
      append-to-body
      :before-close="simpleDialogClose"
      :visible.sync="showSimpleDialog"
    >
      <el-select
        v-model="simpleTagGroupList"
        placeholder="请选择标签组名"
        multiple
        clearable
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.tagGroupName"
          :value="item.id"
        ></el-option>
      </el-select>
      <div class="button-list" style="margin-top: 20px; text-align: center">
        <el-button type="primary" @click="Ok"> 确定</el-button>
        <el-button @click="simpleDialogClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTagItem,
  delTagItem,
  editTagItem,
  unBindTagItem,
  getTagItemList,
  getTagGroupList
} from '@/api/material'
export default {
  data () {
    return {
      total: 0,
      showDialog: false,
      page: 0,
      pageSize: 15,
      selection: [],
      showSimpleDialog: false,
      search: '',
      showEditDialog: false,
      curInfo: null,
      tagItemNames: '',
      tagGroupIds: null,
      simpleTagGroupList: [],
      copyCurInfoName: '', // 标签项名的副本
      rowList: [
        {
          label: '序号',
          prop: 'id',
          width: 50
        },
        {
          label: '标签名',
          prop: 'name',
          slot: true,
          width: 150
        },
        {
          label: '关联组',
          prop: 'tagGroups',
          slot: true,
          width: 300
        },
        {
          label: '关联素材',
          prop: 'bindCount'
        }
      ],
      dataList: [],
      options: [],
      loading: false
    }
  },
  mounted () {
    this.initData()
  },
  watch: {},
  methods: {
    initData () {
      return new Promise((resolve, reject) => {
        this.loading = true
        const paramsOne = {
          page: this.page,
          pageSize: this.pageSize,
          tagItemName: this.search
        }
        const paramsTwo = { pageSize: 999 }
        Promise.all([getTagItemList(paramsOne), getTagGroupList(paramsTwo)])
          .then((res) => {
            const [res1, res2] = res
            res1.list.forEach((item) => {
              item.isEditTitle = false
            })
            this.dataList = res1.list
            this.total = res1.total
            this.options = res2.list
            resolve()
          })
          .catch((e) => {
            reject(e)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    simpleDialogClose () {
      this.showSimpleDialog = false
      this.simpleTagGroupList = []
    },
    addTag () {
      this.showDialog = true
    },
    editTitle (row) {
      row.isEditTitle = true
    },
    editTitleInfo (row) {
      editTagItem(row.id, {
        tagItemName: row.name
      }).then(() => {
        this.$message.success('修改成功!')
        this.initData()
      })
    },
    pageChange (page) {
      this.page = page
      this.initData()
    },
    selectChange (select) {
      this.selection = select
    },
    editItem (row) {
      this.showEditDialog = true
      this.curInfo = row
      this.copyCurInfoName = row.name
    },
    unBindItem (val, flag = true, row) {
      unBindTagItem(row ? row.id : this.curInfo.id, val.id).then(async () => {
        this.$message.success('解绑成功!')
        await this.initData() // 不分页
        if (flag) {
          this.curInfo = this.dataList.find(
            (item) => item.id === this.curInfo.id
          )
        }
      })
    },
    setLinkTag (row) {
      getTagGroupList({
        pageSize: 999
      }).then((res) => {
        this.options = res.list
        this.showSimpleDialog = true
        this.curInfo = row
        if (this.curInfo.tagGroups) {
          this.simpleTagGroupList = this.curInfo.tagGroups.map(
            (item) => item.id
          )
        }
      })
    },
    delGroup () {
      const ids = this.selection.map((item) => item.id)
      this.$confirm('确定删除吗？删除的内容将无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTagItem(ids).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    delItem (row) {
      this.$confirm('确定删除吗？删除的内容将无法恢复!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delTagItem(row.id).then(() => {
            this.$message.success('删除成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    handleClose () {
      this.showDialog = false
    },
    handleEditClose () {
      this.showEditDialog = false
    },
    handleOk () {
      if (!this.copyCurInfoName) return this.$message.warning('请输入标签项名称!')
      editTagItem(this.curInfo.id, {
        tagItemName: this.copyCurInfoName
      }).then(() => {
        this.$message.success('修改成功!')
        this.showEditDialog = false
        this.initData()
        this.resetData()
      })
    },
    handleCancel () {
      this.showEditDialog = false
      this.resetData()
    },
    handleComfirm () {
      if (!this.tagItemNames) return this.$message.warning('请输入名称！')
      addTagItem({
        tagItemName: this.tagItemNames,
        tagGroupIds:
          Array.isArray(this.tagGroupIds) && this.tagGroupIds.length > 0
            ? this.tagGroupIds
            : null
      }).then(() => {
        this.$message.success('新增成功!')
        this.showDialog = false
        this.initData()
        this.resetData()
      })
    },
    Cancel () {
      this.showDialog = false
      this.resetData()
    },
    Ok () {
      if (this.simpleTagGroupList.length === 0) { return this.$message.warning('请选择标签组!') }
      editTagItem(this.curInfo.id, {
        tagItemName: this.curInfo.name,
        tagGroupIds: this.simpleTagGroupList
      }).then(() => {
        this.$message.success('关联成功!')
        this.simpleDialogClose()
        this.initData()
      })
    },

    resetData () {
      this.curInfo = null
      this.tagItemNames = ''
      this.tagGroupIds = null
      this.copyCurInfoName = ''
    }
  },
  components: {}
}
</script>
<style lang="scss" scoped>
.single-tag-item {
  background: #fff;
}
.link-group-list {
  max-height: 92px;
  overflow: auto;
  padding: 5px 0px;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .group-item {
    font-size: 12px;
    position: relative;
    padding: 2px 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    transition: all 0.3s;
    margin: 0 10px 10px 0;
    .del-icon {
      position: absolute;
      top: -20%;
      left: 90%;
      color: #fd5d5a;
      display: none;
    }
    &:hover {
      color: #fd5a5d;
      border-color: #fd5a5d;
      .del-icon {
        display: block;
        cursor: pointer;
      }
    }
  }
}
.button-list {
  margin-top: 20px;
  text-align: center;
}
.container {
  .fixed-part {
    position: sticky;
    top: 0;
    .part {
      margin-bottom: 20px;
    }
    .part-one {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      > span:nth-last-of-type(1) {
        margin-left: auto;
      }
    }
  }
  .group-list {
    height: 500px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    > p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #f1f1f1;
      margin-bottom: 10px;
      &:hover {
        > span {
          color: #00bf8a;
        }
      }
      .del-button {
        margin-left: auto;
      }
    }
  }
}
</style>
