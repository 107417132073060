import request from './config'

// *文案相关

// 文案库 列表
export function getDocWareList (params) {
  return request.get('/document/ware', {
    params
  })
}

// 新增文案项
export function addDocWareItem (content) {
  return request.post(`/document/ware?content=${content}`)
}

// 修改文案项

export function editDocWareItem (id, content) {
  return request.put(`/document/ware/${id}?content=${content}`)
}

// 删除文案项

export function delDocWareItem (id) {
  return request.delete(`/document/ware/${id}`)
}

// 批量删除文案
export function delBatchDocWare(ids) {
  return request.delete('/document/ware',{data:ids})
}

// 文案库详情

export function getDocWareDetail (id) {
  return request.post(`/document/ware/${id}`)
}

// *标签组

// 列表
export function getTagGroupList (params) {
  return request.get('/tag/group', { params })
}

// 新增标签组

export function addTagGroup (params) {
  return request.post('/tag/group', params)
}

// 修改标签组名称

export function editTagGroupName (id, tagGroupName) {
  return request.put(`/tag/group/${id}?tagGroupName=${tagGroupName}`)
}
// 修改标签组

export function editTagGroup(id,params) {
  return request.put(`/tag/group/${id}`, params)
}

// 修改标签组状态

export function editTagGroupStatus (id, status) {
  return request.put(`/tag/group/status/${id}?status=${status}`)
}

// 修改标签组绑定的标签项

export function editTagGroupOfTagItem (id, params) {
  return request.post(`/tag/group/bind/item/${id}`, params)
}

// 取消标签组标签绑定

export function delUnbindTag (id, itemId) {
  return request.delete(`/tag/group/unbind/item/${id}?itemId=${itemId}`)
}

// 删除标签组

export function delTagGroup (id) {
  return request.delete(`/tag/group/${id}`)
}
// 批量删除标签组
export function delBatchTagGroup (ids) {
  return request.delete('/tag/group', {data:ids,
  })
}

// * 标签项

// 获取标签项列表
export function getTagItemList (params) {
  return request.get('/tag/item', { params })
}

// 新增标签项

export function addTagItem (params) {
  return request.post('/tag/item', params)
}

// 修改标签项

export function editTagItem (id, params) {
  return request.put(`/tag/item/${id}`, params)
}

// 删除标签项

export function delTagItem (id) {
  return request.delete(`/tag/item/${id}`)
}
// 批量删除标签项

export function delBatchTagItem(ids){
  return request.delete('/tag/item',{
    data:ids
  })
}

// 标签项解绑

export function unBindTagItem (id, tagGroupId) {
  return request.delete(`/tag/item/unbind/${id}?tagGroupId=${tagGroupId}`)
}

// 查询标签项信息

export function getItemInfo (params) {
  return request.get('/tag/item/info', { params })
}

// *素材库

// 分页查询素材库

export function getMaterialWareList (page,pageSize,params) {
  return request.post(`/material/ware/query?page=${page}&pageSize=${pageSize}`, params)
}

// 新增素材库

export function addMaterialWare (params) {
  return request.post('/material/ware', params)
}

// 修改素材库

export function editMaterialWare (id, params) {
  return request.put(`/material/ware/${id}`, params)
}

// 删除素材库

export function delMaterialWare (id) {
  return request.delete(`/material/ware/${id}`)
}

// 素材库详情

export function getMaterialDetail (id) {
  return request.get(`/material/ware/${id}`)
}

// 分页查询标签项

export function getFilterTagList (params) {
  return request.get('/tag/item/info', { params })
}


// 素材库批量绑定标签

export function batchLinkTags (params) {
  return request.post('/material/ware/bind/items', params)
}
